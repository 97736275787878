<template>
  <div id="theWholeCard">
    <div :id="shop.id" class="carousel carousel-dark slide" data-bs-pause="false">
      <div class="carousel-inner">
        <div
          class="carousel-item imagePart"
          v-for="image in shop.imageUrls"
          :key="image"
          :class="image === shop.imageUrls[0] ? 'active' : ''"
        >

          <router-link :to="rentalDetail" class="imageContainer">
        <img :src="image" class="img-fluid rounded d-block w-100" :alt="shop.id"/>
        <p style="color: white" class="top-right">{{1 + shop.imageUrls.indexOf(image)}} / {{shop.imageUrls.length}}</p>
      </router-link>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        :data-bs-target="'#' + shop.id"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon bclour" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        :data-bs-target="'#' + shop.id"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon bclour" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <div class="">
      <p class="card-text m-0">
        <span class="fst-itali">{{ shop.location }}, Kampala</span>
      </p>
      <p class="card-title m-0">
        <span class="fst-itali">{{ shop.buildingName }}</span>
      </p>
      <p>
        <strong>{{ shop.price }} UGX</strong> / month
      </p>

      <p>
        Contact us on
        <strong><a href="tel: +256788380658">+256 788380658</a></strong>
      </p>
      <router-link :to="contactLink" class="btn btn-primary"
        >Send a request</router-link
      >
    </div>
  </div>
</template>
  
  <script>
export default {
  props: ["shop"],
  computed: {
    contactLink() {
      return "/Offices&Shops/" + this.shop.id + "/contact";
    },
    rentalDetail() {
      return "/Offices&Shops/" + this.shop.id;
    },
  },
  methods: {
    // thisButton() {
    //   console.log(this.shop.ownerName);
    // },
  }
};
</script>
  
  <style scoped>
#theWholeCard {
  max-height: 100vh;
  width: 25rem;
  max-width: 25rem;
}
span {
  font-weight: bold;
}

div .imagePart {
  width: 100%;
  height: 20rem;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.imageContainer {
  position: relative;
  text-align: center;
  color: white;
}

.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  background-color: rgb(65, 59, 59);
  padding-left: .3rem;
  padding-right: .3rem;
  border-radius: .2rem;
}

.bclour {
  background-color: black;
  border-radius: .3rem;
}
</style>