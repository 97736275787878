<template>
  <div>
    <section>
      <div id="filter">
        <base-button class="m-3" @click="toggleFilter">Filter</base-button>
      </div>
      <shop-filter
        v-if="showFilter"
        @search="searchCriteria"
      ></shop-filter>
    </section>
    <section>
      <div class="container">
        <div class="row justify-content-around">
          <div v-if="isLoading">
            <base-spinner></base-spinner>
          </div>
          <shop-item
            class="col-sm-5 col-md-4 mt-3 m-1"
            v-for="shop in filteredShops"
            :key="shop.id"
            :shop="shop"
          ></shop-item>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
import ShopItem from "../../components/shops/ShopItem.vue";
import ShopFilter from "../../components/shops/ShopFilter.vue";
export default {
  components: {
    ShopItem,
    ShopFilter,
  },
  data() {
    return {
      showFilter: false,
      isLoading: false,
      location: "All",
      price: 100000000,
      locations: [
        "Bugoloobi",
        "Bukoto",
        "Busega",
        "Butabika",
        "Bwaise",
        "Ggaba",
        "Kabalagala",
        "Kaleerwa",
        "Kampala Hill",
        "Kasanga",
        "Kanyanya",
        "Kasubi hill",
        "Katwe",
        "Kawaala",
        "Kawempe",
        "Kibuli",
        "Kibuye, Uganda",
        "Kigoowa",
        "Kikaaya",
        "Kisaasi",
        "Kololo",
        "Kulambiro",
        "Kyebando",
        "Kisenyi",
        "Lubaga",
        "Lugogo, Kampala",
        "Lungujja",
        "Luzira",
        "Makerere",
        "Makerere Kikoni",
        "Makindye",
        "Mbuya",
        "Mengo",
        "Mpererwe",
        "Mulago",
        "Munyonyo",
        "Muyenga",
        "Naakulabye",
        "Nakasero",
        "Nakawa",
        "Namirembe Hill",
        "Namungoona",
        "Namuwongo",
        "Nateete",
        "Ndeeba",
        "Nsambya",
        "Ntinda",
        "Port Bell",
        "Wandegeya",
      ],
    };
  },
  computed: {
    filteredShops() {
      // finds all shops and then filters them
      const shops = this.$store.getters["shops/properties"];
      return shops.filter((shop) => {
        if (
          this.location === "All" &&
          shop.price <= this.price &&
          !this.isLoading &&
          shop.approved &&
          !shop.booked
        ) {
          return true;
        }

        for (let i = 0; i < this.locations.length; i++) {
          if (
            !this.isLoading &&
            this.location === this.locations[i] &&
            shop.location === this.locations[i] &&
            shop.price <= this.price &&
            shop.approved &&
            !shop.booked
          ) {
            return true;
          }
        }

        return false;
      });
    },
  },
  methods: {
    toggleFilter() {
      // toggles the filter button
      this.showFilter = !this.showFilter;
    },
    async loadShops() {
      // loads all shops from vuex
      this.isLoading = true;
      await this.$store.dispatch("shops/loadShops");
      this.isLoading = false;
    },
    searchCriteria(val) {
      // searches by the given criteria
      this.location = val.location;
      this.price = val.price
    },
  },
  created() {
    this.loadShops();
  },
};
</script>
  
<style scoped>
#filter {
  text-align: center;
}
</style>